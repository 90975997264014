import Link from "next/link";
import styled, { css } from "styled-components";
import { Button } from "antd";

function SectionTitle({ title, buttonTo, buttonTitle, mt }) {
  const withButton = buttonTo && "withButton";
  return (
    <StyledSectionTitle withButton={withButton} mt={mt}>
      <h1>{title}</h1>
      {buttonTo && (
        <Link href={buttonTo}>
          <a>
            <Button>{buttonTitle}</Button>
          </a>
        </Link>
      )}
    </StyledSectionTitle>
  );
}

const StyledSectionTitle = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: ${(props) =>
    props.withButton ? "space-between" : "flex-start"};
  align-items: center;

  ${(props) =>
    props.mt &&
    css`
      margin-top: ${(props) => props.theme.spacing[6]};
    `};
`;

export default SectionTitle;
